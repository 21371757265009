import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import theme from "../theme";

import { ChakraProvider } from "@chakra-ui/react";

import {
  Flex,
  Image,
  Text,
  Stack,
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Button,
} from "@chakra-ui/react";

import { GiSpray } from "react-icons/gi";
import { FiGrid } from "react-icons/fi";
import { FaEuroSign } from "react-icons/fa";
import { BiSort } from "react-icons/bi";

import products from "../products.js";

import accessories from "../images/accessories-min.jpg";
import { graphql, Link } from "gatsby";

import GlobalStyle from "../globalStyles";

const Produkt = ({ location }) => {
  const formatter = new Intl.NumberFormat("sk-SK", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const productUrl = location.search.split("=")?.[1] || "";
  const setProductUrl = useCallback((newProductUrl) => {
    window.location.search = `?subCategory=${newProductUrl}`;
  }, []);

  const displayProduct = products.filter((product) =>
    productUrl === "" ? true : product.id === productUrl
  );

  const numberOfProducts = displayProduct.length;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {displayProduct.map((product) => (
          <title>DMS-VID - Svet pre vaše auto :: {product.name}</title>
        ))}
      </Helmet>

      <GlobalStyle />

      <ChakraProvider theme={theme}>
        <Navbar
          home="whiteAlpha.600"
          oils="whiteAlpha.600"
          accessories="whiteAlpha.600"
          contact="whiteAlpha.600"
        />

        <Box
          px={{
            base: "24px",
            md: "48px",
            lg: "96px",
            "2xl": "256px",
          }}
          bg="white"
          transition="all 250ms"
          py={{ base: 12, xl: 24 }}
          borderBottom="1px"
          borderColor="blackAlpha.300"
        >
          <Flex
            align="center"
            width="100%"
            borderBottom="1px"
            borderColor="blackAlpha.300"
            flexWrap="wrap"
            justify="space-between"
            color="black.900"
            alignItems="center"
            mt={{ base: 16, xl: 12 }}
          >
            <Flex justify="start" align="center" alignItems="center" py={6}>
              <Text fontWeight="600" color="black.800">
                <Link to="/">Domov</Link> /{" "}
                {displayProduct.map((product) => product.type === "olej") ? (
                  <Link to="/oleje">Oleje a kvapaliny</Link>
                ) : (
                  <Link to="/doplnky">Autodoplnky</Link>
                )}
              </Text>
            </Flex>
          </Flex>

          {displayProduct.map((product) => (
            <Flex
              mt={8}
              w="full"
              flexWrap="wrap"
              transition="all 250ms"
              key={product.id}
            >
              <Box width="30%" paddingRight={6}>
                <Image
                  rounded={"lg"}
                  height="100%"
                  objectFit="contain"
                  src={product.image}
                />
              </Box>
              <Box width="70%">
                <Stack pt={7}>
                  <Text
                    color={"black.500"}
                    fontSize={"md"}
                    textTransform={"uppercase"}
                  >
                    {product.brand}
                  </Text>
                  <Heading
                    fontSize={"3xl"}
                    fontFamily={"body"}
                    fontWeight={700}
                  >
                    {product.name}
                  </Heading>

                  <Stack direction={"row"} align={"center"} padding="1rem 0">
                    <Text fontWeight={800} fontSize={"2xl"} color="red.500">
                      {product.price}€
                    </Text>
                    <Text textDecoration={"line-through"} color={"gray.600"}>
                      {formatter.format(product.price + product.price / 3)}€
                    </Text>
                  </Stack>

                  <Text lineHeight={1.8}>{product.desc}</Text>
                </Stack>
              </Box>
            </Flex>
          ))}
        </Box>

        <Footer />
      </ChakraProvider>
    </>
  );
};

export default Produkt;

export const queryProdukt = graphql`
  query PageQueryProdukt {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
